import Vue from "vue"
import VueApollo from "vue-apollo"
import ApolloClient from "apollo-boost"
Vue.use(VueApollo)

const meta = document.querySelector('meta[name="csrf-token"]')
const token = meta ? meta.getAttribute("content") : ""

const uri = `${window.location.protocol}//${window.location.host}/graphql`

export const client = new ApolloClient({
  uri,
  credentials: "include",
  headers: {
    "X-CSRF-TOKEN": token,
  },
  resolvers: {},
})

export const apolloProvider = new VueApollo({
  defaultClient: client,
})
